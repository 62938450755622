import React from "react";
import { Spinner } from "reactstrap";
import { getLogoutAPIUrl } from "../../components/dataLayer/EmployeeLayer";
import { logoutUser } from "../../layouts/components/navbar/LogoutUser";
import { toast } from "react-toastify";

export const logoutUserAPI = async (history) => {
  try {
    history.push("/logout");
    const payload = {
      timeout: true,
    };
    const response = await getLogoutAPIUrl(payload);
    if (response?.status === 200) {
      logoutUser(history);
      history.push("/login");
      window.location.reload();
    } else {
      toast.error(response?.data?.message);
      logoutUser(history);
      history.push("/login");
      window.location.reload();
    }
  } catch (error) {
    toast.error(error);
    logoutUser(history);
    history.push("/login");
  }
};
const Logout = () => {
  return (
    <div className="text-center" style={{ marginTop: "12rem" }}>
      <Spinner type="grow" color="primary" size="lg" />
    </div>
  );
};

export default Logout;
